import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VCard,[_c(VCardTitle,[_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCardText,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.store.getters.allergens,"search":_vm.search,"item-key":"id","loading":_vm.tableLoading,"footer-props":_vm.footerProps,"loading-text":"Loading allergens. Please wait ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VRow,[_c(VBtn,{staticClass:"mb-2 ml-2",attrs:{"color":"success","dark":"","to":"/allergens/add"}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("add")]),_vm._v("Add new allergen ")],1)],1),_c('confirmation-dialog',{ref:"deleteAllergen"})]},proxy:true},{key:`item.actions`,fn:function({ item }){return [_c('router-link',{attrs:{"to":`/allergens/edit?id=${item.id}`,"id":"editLink"}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("edit")])],1),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("delete")])]}}],null,true)})],1)],1),_c(VSnackbar,{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("clear")])],1)]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }